import { ACCESS_TOKEN_LS_KEY } from '../constants/auth.js';
import { router } from '@/router/router'

export const logout = async () => {
  localStorage.removeItem(ACCESS_TOKEN_LS_KEY)

  return router.push({ name: 'auth:login' })
}

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN_LS_KEY)
}

export const setAccessToken = (token) => {
  localStorage.setItem(ACCESS_TOKEN_LS_KEY, token)
}

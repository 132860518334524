import VueRouter from 'vue-router';
import { getAccessToken } from '@/utils/auth';
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/store/userStore'
import { usePermissions } from '@/composables/permissions'
import { useNotification } from '@/composables/notification'
import { PERMISSIONS } from '@/constants/permissions'

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: { name: 'user:list' }
  },
  {
    name: 'user',
    path: '/users',
    component: () => import('../layouts/DefaultLayout.vue'),
    redirect: { name: 'user:list' },
    meta: {
      label: 'Users',
    },
    children: [
      {
        path: '',
        name: 'user:list',
        component: () => import('../pages/user/UsersList.vue'),
        meta: {
          label: 'Users',
          permissions: [PERMISSIONS.USER_VIEW_LIST]
        }
      },
      {
        path: ':id/details',
        name: 'user:details',
        component: () => import('../pages/user/UserDetails.vue'),
        meta: {
          label: 'User Details',
          permissions: [PERMISSIONS.USER_VIEW_DETAILS]
        }
      },
      {
        path: ':id/edit',
        name: 'user:edit',
        component: () => import('../pages/user/UserUpdate.vue'),
        meta: {
          label: 'User Edit',
          permissions: [PERMISSIONS.USER_EDIT]
        }
      }
    ]
  },
  {
    name: 'auth',
    path: '/login',
    component: () => import('../layouts/AuthLayout.vue'),
    redirect: { name: 'auth:login' },
    children: [
      {
        path: '',
        name: 'auth:login',
        component: () => import('../pages/LoginPage/LoginPage.vue')
      }
    ]
  },
  {
    name: 'attention',
    path: '/attention',
    component: () => import('../layouts/DefaultLayout.vue'),
    redirect: { name: 'attention:user-duplicates' },
    meta: {
      label: 'Needs Attention'
    },
    children: [
      {
        path: 'user-duplicates',
        name: 'attention:user-duplicates',
        component: () => import('../pages/attention/UserDuplicates.vue'),
        meta: {
          label: 'User Duplicates',
          permissions: [PERMISSIONS.ATTENTION_VIEW_USER_DUPLICATES]
        }
      },
      {
        path: 'invalid-transactions',
        name: 'attention:invalid-transactions',
        component: () => import('../pages/attention/InvalidTransactions.vue'),
        meta: {
          label: 'Invalid Transactions',
          permissions: [PERMISSIONS.ATTENTION_VIEW_INVALID_TRANSACTIONS]
        }
      },
      {
        path: 'non-matching-iban',
        name: 'attention:non-matching-iban',
        component: () => import('../pages/attention/NonMatchingIban.vue'),
        meta: {
          label: 'Non Matching IBAN',
          permissions: [PERMISSIONS.ATTENTION_VIEW_NON_MATCHING_IBAN]
        }
      },
      {
        path: 'wrong-details',
        name: 'attention:wrong-details',
        component: () => import('../pages/attention/WrongDetails.vue'),
        meta: {
          label: 'Wrong Details',
          permissions: [PERMISSIONS.ATTENTION_VIEW_WRONG_DETAILS]
        }
      },
      {
        path: 'rejected-applications',
        name: 'attention:rejected-applications',
        component: () => import('../pages/attention/RejectedApplications/RejectedApplications.vue'),
        meta: {
          label: 'Rejected Applications',
          permissions: [PERMISSIONS.ATTENTION_VIEW_REJECTED_APPLICATIONS]
        }
      }
    ]
  },
  {
    path: '/backoffice',
    name: 'backoffice',
    component: () => import('../layouts/DefaultLayout.vue'),
    redirect: { name: 'backoffice:employees-list' },
    meta: {
      label: 'Backoffice'
    },
    children: [
      {
        path: 'employees',
        name: 'backoffice:employees-list',
        component: () => import('../pages/backoffice/BackofficeEmployeesList.vue'),
        meta: {
          label: 'Employees',
          permissions: [PERMISSIONS.BACKOFFICE_VIEW_EMPLOYEES_LIST]
        }
      },
      {
        path: 'employees/edit/:id',
        name: 'backoffice:employee-edit',
        component: () => import('../pages/backoffice/BackofficeEmployeeUpdate.vue'),
        meta: {
          label: 'Edit Employee',
          permissions: [PERMISSIONS.BACKOFFICE_EDIT_EMPLOYEE]
        }
      },
      {
        path: 'employees/add',
        name: 'backoffice:employee-create',
        component: () => import('../pages/backoffice/BackofficeEmployeeCreate.vue'),
        meta: {
          label: 'New Employee',
          permissions: [PERMISSIONS.BACKOFFICE_CREATE_EMPLOYEE]
        }
      }
    ]
  },
  {
    path: '/audit-trail',
    name: 'audit-trail',
    component: () => import('../layouts/DefaultLayout.vue'),
    redirect: { name: 'audit-trail:list' },
    meta: {
      label: 'Audit Trail'
    },
    children: [
      {
        path: '',
        name: 'audit-trail:list',
        component: () => import('../pages/merchantFunds/AuditTrail.vue'),
        meta: {
          label: 'Audit Trail',
          permissions: [PERMISSIONS.AUDIT_TRAIL_VIEW]
        }
      },
      {
        path: 'debug',
        name: 'audit-trail:debug',
        component: () => import('../pages/merchantFunds/DebugFormatting.vue'),
        meta: {
          label: 'Debug formatting',
        }
      }
    ]
  },
  {
    path: '/merchants',
    name: 'merchant',
    component: () => import('../layouts/DefaultLayout.vue'),
    meta: {
      label: 'Merchants'
    },
    redirect: { name: 'merchant:list' },
    children: [
      {
        path: 'list',
        name: 'merchant:list',
        component: () => import('../pages/merchant/MerchantsList.vue'),
        meta: {
          label: 'List',
          permissions: [PERMISSIONS.MERCHANT_VIEW_LIST]
        }
      },
      {
        path: 'balance',
        name: 'merchant:balances-list',
        component: () => import('../pages/merchant/MerchantBalancesList.vue'),
        meta: {
          label: 'Bank Balance',
          permissions: [PERMISSIONS.MERCHANT_VIEW_BALANCE_LIST]
        }
      },
      {
        path: 'adjustments',
        name: 'merchant:adjustments-list',
        component: () => import('../pages/merchantFunds/AdjustmentsList.vue'),
        meta: {
          label: 'Funds Adjustments',
          permissions: [PERMISSIONS.MERCHANT_VIEW_FUNDS_ADJUSTMENTS_LIST]
        }
      },
      {
        path: 'balance-alerts',
        name: 'merchant:balance-alerts',
        component: () => import('../pages/merchantFunds/BalanceAlerts/BalanceAlerts.vue'),
        meta: {
          label: 'Balance Alerts',
          permissions: [PERMISSIONS.MERCHANT_VIEW_BALANCE_ALERTS]
        }
      },
      {
        path: ':id/details',
        name: 'merchant:details',
        component: () => import('../pages/merchant/MerchantDetails.vue'),
        meta: {
          label: 'Merchant Details',
          permissions: [PERMISSIONS.MERCHANT_VIEW_DETAILS]
        }
      },
    ]
  },
  {
    path: '/tools',
    name: 'tools',
    component: () => import('../layouts/DefaultLayout.vue'),
    redirect: { name: 'tools:test-transaction-create' },
    meta: {
      label: 'Tools'
    },
    children: [
      {
        path: 'transactions/create',
        name: 'tools:test-transaction-create',
        component: () => import('../pages/tools/TestTransactionCreate.vue'),
        meta: {
          label: 'Create Transaction',
          permissions: [PERMISSIONS.TOOLS_TEST_TRANSACTION_CREATE]
        }
      },
      {
        path: 'whitelisting',
        name: 'tools:whitelisting',
        component: () => import('../pages/tools/WhitelistingTesters.vue'),
        meta: {
          label: 'Whitelisting Testers',
          permissions: [PERMISSIONS.TOOLS_WHITELISTING]
        }
      }
    ]
  },
  {
    path: '/transactions',
    name: 'transaction',
    component: () => import('../layouts/DefaultLayout.vue'),
    redirect: { name: 'transaction:list' },
    meta: {
      label: 'Transactions'
    },
    children: [
      {
        path: '',
        name: 'transaction:list',
        component: () => import('../pages/transaction/TransactionsList.vue'),
        meta: {
          label: 'Transactions',
          permissions: [PERMISSIONS.TRANSACTION_VIEW_LIST]
        }
      },
      {
        path: ':id/details',
        name: 'transaction:details',
        component: () => import('../pages/transaction/TransactionDetails.vue'),
        meta: {
          label: 'Transaction Details',
          permissions: [PERMISSIONS.TRANSACTION_VIEW_DETAILS]
        }
      }
    ]
  },
  {
    path: '/reports',
    name: 'report',
    component: () => import('../layouts/DefaultLayout.vue'),
    meta: {
      label: 'Reports'
    },
    redirect: { name: 'report:daily-performance' },
    children: [
      {
        path: 'daily-performance',
        name: 'report:daily-performance',
        component: () => import('../pages/reports/DailyPerformanceReport.vue'),
        meta: {
          label: 'Daily Performance',
          permissions: [PERMISSIONS.REPORT_VIEW_DAILY_PERFORMANCE]
        }
      },
      {
        path: 'finance-payoro',
        name: 'report:finance-payoro',
        component: () => import('../pages/reports/FinanceReport.vue'),
        meta: {
          label: 'Finance Reporting',
          permissions: [PERMISSIONS.REPORT_VIEW_FINANCE_PAYORO]
        }
      },
    ]
  },
  {
    path: '/finance-tools',
    name: 'finance-tool',
    component: () => import('../layouts/DefaultLayout.vue'),
    redirect: { name: 'finance-tool:ledger-adjustment' },
    meta: {
      label: 'Finance Tools'
    },
    children: [
      {
        path: 'ledger-adjustment',
        name: 'finance-tool:ledger-adjustment',
        component: () => import('../pages/financeTools/LedgerAdjustment.vue'),
        meta: {
          label: 'Ledger Adjustment',
          permissions: [PERMISSIONS.FINANCE_TOOL_VIEW_LEDGER_ADJUSTMENT]
        }
      },
      {
        path: 'statements-upload',
        name: 'finance-tool:statements-upload',
        component: () => import('../pages/financeTools/StatementsUpload.vue'),
        meta: {
          label: 'Statements Upload',
          permissions: [PERMISSIONS.FINANCE_TOOL_VIEW_STATEMENTS_UPLOAD]
        }
      },
      {
        path: 'merchant-finance',
        name: 'finance-tool:merchant-finances',
        component: () => import('../pages/financeTools/MerchantFinanceReportTool.vue'),
        meta: {
          label: 'Merchant Finance Report Tool',
          permissions: [PERMISSIONS.FINANCE_TOOL_VIEW_MERCHANT_FINANCES]
        }
      },
      {
        path: 'merchant-report-maintenance',
        name: 'finance-tool:merchant-report-maintenance',
        component: () => import ('../pages/financeTools/MerchantReportMaintenanceTool.vue'),
        meta: {
          label: 'Merchant Finance Report Maintenance Tool',
          permissions: [PERMISSIONS.FINANCE_TOOL_VIEW_MERCHANT_REPORT_MAINTENANCE]
        }
      }
    ]
  },
  {
    path: '/security',
    name: 'security',
    component: () => import('../layouts/DefaultLayout.vue'),
    meta: {
      label: 'Security'
    },
    redirect: { name:'security:two-factor' },
    children: [
      {
        path: '',
        name:'security:two-factor',
        component: () => import('../pages/security/TwoFactorSettings.vue'),
        meta: {
          label: 'Two-Factor Settings'
        }
      }
    ]
  },
  {
    path: '*',
    name: 'not-found',
    component: () => import('../layouts/DefaultLayout.vue'),
    redirect: { name: 'not-found:page' },
    children: [
      {
        path: '',
        name: 'not-found:page',
        component: () => import('../pages/NotFound/NotFound.vue'),
        meta: {
          hideBreadcrumb: true
        }
      }
    ]
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export const setupHooks = () => {
  router.beforeEach((to, from, next) => {
    const { currentUser } = storeToRefs(useUserStore())

    if (!currentUser.value) {
      return next()
    }

    const { hasPermissions } = usePermissions()
    const { showWarning } = useNotification()

    const requiredPermissions = to.meta.permissions ?? []

    if (!requiredPermissions.length || hasPermissions(...requiredPermissions)) {
      return next()
    }

    showWarning('You do not have permissions to access this page', {
      summary: 'Access Denied',
    })

    next(false)
  })
}

export {
  router,
}

export default (api) => ({
  getFilteredMerchants: (body, params) => api.post('/merchants/filter', body, { params }),
  getMerchantInfo: (id) => api.get(`/merchants/${id}`),
  updateMerchant: (id, data) => api.put(`/merchants/${id}`, data),
  createMerchant: (data) => api.post('/merchants', data),
  getMerchantEmployees: (id, params) => api.get(`/merchants/${id}/employees`, { params }),
  getMerchantEmployeeInfo: (id) => api.get(`/merchants/employees/${id}`),
  createMerchantEmployee: (id, data) => api.post(`/merchants/${id}/employees`, data),
  updateMerchantEmployee: (id, data) => api.put(`/merchants/employees/${id}`, data),
  getAccounts: (params) => api.get('/merchants/merchants-accounts', { params }),
  updateMerchantConfigs: (id, data) => api.patch(`/merchants/${id}`, data),
})

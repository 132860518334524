import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, email, length, min_value, max_value, double, numeric } from 'vee-validate/dist/rules'

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

extend('required', {
  ...required,
  message: 'This field is required'
})
extend('email', {
  ...email,
  message: 'This field must be a valid email address'
})
extend('length', {
  ...length,
  message: 'This field must be {length} characters long'
})

extend('min_value', {
  ...min_value,
  message: 'This field must be at least {min}'
})

extend('max_value', {
  ...max_value,
  message: 'This field must be at most {max}'
})

extend('positive_integer', {
  validate: (value) => {
    return +value >= 0
  },
  message: 'This field must be a valid positive integer'
})

extend('uuid', {
  validate: (value) => {
    return /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(value)
  },
  message: 'This field must be a valid UUID'
})

extend('phone', {
  validate: (value) => {
    return /^\+[1-9]\d{4,14}$/.test(value)
  },
  message: 'This field must be a valid phone number including "+", country code and your number'
})

extend('double', {
  ...double,
  message: 'This field must be a valid decimal'
})

extend('numeric', {
  ...numeric,
  message: (_, { _value_ }) => {
    if (Array.isArray(_value_)) {
      return 'This field must contain only numbers'
    }

    return 'This field must be a valid positive number'
  }
})
